import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">NEUTROGENA®</a>
                <span>&gt;</span>
                <a href="/site/produkty/">Opis</a>
                <span>&gt;</span>
                <a href="/product/48/" className="blue">
                  {" "}
                  <p className="p1">
                    NEUTROGENA<sup>®</sup>&nbsp;Visibly Clear<sup>®</sup>
                    &nbsp;Pink Grapefruit Oil-Free Moisturiser - krem
                    nawilżający do twarzy&nbsp;
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/site/assets/Uploads/NOWY-VisiblyClear-PG-Moisturiser-Pudelko.jpg"
                      alt="NOWY VisiblyClear PG Moisturiser Pudelko"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p className="p1">
                      NEUTROGENA<sup>®</sup>&nbsp;Visibly Clear<sup>®</sup>
                      &nbsp;Pink Grapefruit Oil-Free Moisturiser - krem
                      nawilżający do twarzy&nbsp;
                    </p>
                  </h2>
                  <dl>
                    <dt>Opis:</dt>
                    <dd>
                      <p className="p1">
                        <span className="s1">
                          Nawilżający krem do twarzy o świeżym zapachu
                          przeznaczony do codziennego użytku, pozostawia skórę
                          wyglądającą pięknie i zdrowo.&nbsp;
                        </span>
                      </p>
                      <ul>
                        <li className="li1">
                          <span className="s1">
                            Nawilżenie: lekka formuła oil-free kremu zapewnia
                            24h nawilżenie bez zatykania porów. Szybko się
                            wchłania pozostawiając skórę miękką i gładką.&nbsp;
                          </span>
                        </li>
                        <li className="li1">
                          <span className="s1">
                            Oczyszczenie: krem wzbogacony o technologię
                            MicroClear<sup>®</sup>, stosowany codziennie pomaga
                            redukować istniejące wypryski i zapobiega
                            powstawaniu nowych. Poprawia wygląd cery i
                            pozostawia skórę bardziej gładką.
                          </span>
                        </li>
                        <li className="li1">
                          <span className="s1">
                            Odświeżenie: lekka formuła z delikatną nutą różowego
                            grapefruita zapewnia nawilżenie i pobudza zmysły,
                            dając uczucie świeżości.
                          </span>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Stosowanie:</dt>
                    <dd>
                      Najpierw należy zastosować żel lub peeling do twarzy z
                      linii Pink Grapefruit. Następnie nałożyć krem na skórę
                      twarzy i szyi. Stosować codziennie rano i wieczorem.
                      Unikać kontaktu z oczami. W przypadku kontaktu z oczami,
                      dokładnie spłukać wodą.{" "}
                    </dd>
                  </dl>
                  <dl>
                    <dt>Opakowanie:</dt>
                    <dd>50 ml</dd>
                  </dl>
                  <dl className="last">
                    <dt>Cena:</dt>
                    <dd>
                      21,99*{" "}
                      <p>
                        {" "}
                        <br />
                        *cena detaliczna sugerowana przez producenta
                      </p>
                    </dd>
                  </dl>
                </div>
                <div className="fb">
                  <div
                    style={{
                      width: "250px",
                      height: "auto",
                      float: "left"
                    }}
                  >
                    <iframe
                      src="//www.facebook.com/plugins/like.php?href=&send=false&layout=button_count&width=200&show_faces=false&font&colorscheme=light&action=like&height=21&locale=pl_PL"
                      scrolling="no"
                      frameBorder="0"
                      style={{
                        border: "none",
                        overflow: "hidden",
                        width: "200px",
                        height: "21px"
                      }}
                      allowTransparency="true"
                    />
                  </div>
                </div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobacz podobne produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/66/"
                  >
                    <img
                      src="/site/assets/Uploads/zel-do-mycia-twarzy-200ml.png"
                      alt="zel do mycia twarzy 200ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Nawadniający żel do mycia twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/67/"
                  >
                    <img
                      src="/site/assets/Uploads/zelowe-mleczko-nawilzajace-200ml.png"
                      alt="zelowe mleczko nawilzajace 200ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Nawadniające mleczko do twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/68/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-Peeling-150ml.png"
                      alt="HydroBoost Peeling 150ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Wygładzający peeling do twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/69/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-CityShield-TUBE-FOP.png"
                      alt="HydroBoost CityShield TUBE FOP"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> City
                        shield nawadniający krem do twarzy SPF 25
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/70/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-EyeCream-TUBE-FOP.png"
                      alt="HydroBoost EyeCream TUBE FOP"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> żel-krem
                        pod oczy przeciw oznakom zmęczenia
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/site/o-neutrogenie/kim-jestesmy/">
                neutrogena<sup>®</sup> obecnie
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/formula-norweska/">
                czym jest formuła norweska?
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/historia/">poznaj naszą historię</a>
              <span>|</span>
              <a href="/site/produkty/">zobacz produkty neutrogena</a>
            </div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              © JNTL Consumer Health (Poland) sp. z o.o., 2023. Ostatnia aktualizacja 01.08.2023<br></br>
Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000937574, kapitał zakładowy w wysokości 107.357.450 PLN, NIP 5272980564. JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną odpowiedzialność za treści zawarte na niniejszej stronie. Strona jest przeznaczona dla użytkowników z Polski.<br></br>
Niniejsza witryna zawiera łącza do witryn, które nie podlegają naszej polityce prywatności. Zachęcamy do zapoznawania się z politykami prywatności każdej odwiedzanej witryny.
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
      </div>
    );
  }
}

export default Page;